import { Content } from '@vccp/bernadette-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React from 'react';
import Image from '../Image/Image';
import * as styles from './ThinkingArticleIntro.module.scss';
const ThinkingArticleIntro = (articleData) => {
  const { title, image, date, body, author, subheading } = articleData;

  return (
    <Content>
      <div className={styles.articleIntroWrapper}>
        <div className={styles.articleIntroText}>
          <span className={styles.articleIntroDate}>{date}</span>
          <h1 className={styles.articleIntroTitle}>{title}</h1>
          {subheading && (
            <h2 className={styles.articleIntroSubHeading}>
              {subheading.subheading}
            </h2>
          )}
          {author && (
            <span className={styles.articleIntroAuthor}>
              {author.authorName}, {author.authorJobTitle}
            </span>
          )}
        </div>
        {image && (
          <div className={styles.articleIntroImage}>
            {image && <Image {...image} />}
          </div>
        )}
        <div className={styles.articleIntroRichText}>
          {body && renderRichText(body)}
        </div>
      </div>
    </Content>
  );
};

export default ThinkingArticleIntro;
